<template>
  <getecma-single-content-layout content-class="scroll pe--xxl">
    <template v-if="region" #content>
      <getecma-breadcrumb :items="itemsBreadcrumb" class="mt--xl" />
      <getecma-row justify="center" class="d-flex align-items--center d-xs-flex-column">
        <getecma-col :xs="24" :span="17" justify="left">
          <getecma-region-view-information :region="region" />
        </getecma-col>
      </getecma-row>
    </template>
  </getecma-single-content-layout>
</template>

<script>
import { toastError } from '@/services/toastService';
import { getRegionById } from '@/modules/region/region.service';
import { MY_SPACE_URL } from '@/modules/my-space/my-space.constants';

import GetecmaRegionViewInformation from '@/modules/region/components/RegionViewComponent.vue';
import GetecmaSingleContentLayout from '@/layouts/SingleContentLayout.vue';
import GetecmaBreadcrumb from '@/components/breadcrumb/Breadcrumb.vue';

export default {
  name: 'GetecmaRegionViewPage',
  components: {
    GetecmaRegionViewInformation,
    GetecmaSingleContentLayout,
    GetecmaBreadcrumb,
  },
  data() {
    return {
      itemsBreadcrumb: [
        { name: 'Dashboard', path: '/' },
        { name: 'Rotas', path: MY_SPACE_URL.regions.path },
        { name: 'Visualizar', path: this.$route.path },
      ],
      region: null,
      pagination: {
        page: 1,
        limit: 5,
      },
    };
  },
  computed: {
    region_id() {
      return this.$route.params?.id;
    },
  },
  mounted() {
    this.fetch();
  },
  methods: {
    fetch() {
      getRegionById(this.region_id)
        .then(data => {
          this.region = data;
        })
        .catch(() => toastError('Erro ao obter rota por ID'));
    },
  },
};
</script>
