<template>
  <div class="row mt--lg">
    <div class="col-md-9 mt--sm">
      <getecma-p color="theme-900-100" size="md">
        <b>Rota:</b> {{ region.name }}
      </getecma-p>
    </div>
    <div class="col-md-9 mt--sm">
      <getecma-p color="theme-900-100" size="md">
        <b>Bairros:</b> <span v-for="neighborhood in neighborhoods" :key="neighborhood.n_name"><br>• {{ neighborhood.n_name }} </span>
      </getecma-p>
    </div>
  </div>
</template>

<script>
import { toastError } from '@/services/toastService';
import { fetchNeighborhoodsByRegion } from '@/modules/region/region.service';

export default {
  name: 'GetecmaRegionViewInformation',
  props: {
    region: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      neighborhoods: null,
    };
  },
  mounted() {
    this.fetch();
  },
  methods: {
    fetch() {
      fetchNeighborhoodsByRegion(this.region.id)
        .then(data => {
          this.neighborhoods = data.rows;
        })
        .catch(() => toastError('Erro ao obter rota por ID'));
    },
  },
};
</script>
